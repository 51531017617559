import { useMemo } from 'react';
import { gql } from '@ts-gql/tag';

import { useQuery } from '@reckon-web/gql-api-client';

import { ReckonAppDirectory } from '../../../types/ReckonAppDirectory';

const GetAppsQuery = gql`
  query GetUserAccessedAppsQuery {
    authenticatedUser {
      id
      userProducts {
        productType
        url
        logoURL
      }
    }
  }
` as import('../../../../__generated__/ts-gql/GetUserAccessedAppsQuery').type;

export type UseGetAppDirectoryQueryProps = {
  userId: string;
  skip?: boolean;
};

export function useGetAppDirectoryQuery({
  userId,
  skip,
}: UseGetAppDirectoryQueryProps) {
  const { data, loading: isLoading, error, refetch: refetchApps } = useQuery(
    GetAppsQuery,
    {
      skip,
    }
  );

  const userProducts: ReckonAppDirectory = useMemo(() => {
    const userApps = data?.authenticatedUser?.userProducts;

    if (!userApps || isLoading) {
      return [];
    }

    return userApps.map((userApp) => {
      return {
        productType: userApp.productType ?? 'RECKON_ONE',
        url: userApp.url ?? '',
        logoUrl: userApp.logoURL ?? '',
      };
    });
  }, [data?.authenticatedUser?.userProducts, isLoading]);

  return {
    apps: userProducts,
    refetchApps,
    isLoading,
    error: (error instanceof Error && error.message) || '',
  };
}
