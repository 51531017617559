import React, { ReactNode } from 'react';
import { Container } from '@balance-web/container';
import { Stack } from '@balance-web/stack';

type AppSelectorLayoutProps = {
  children: ReactNode;
};

export const AppSelectorLayout = ({ children }: AppSelectorLayoutProps) => {
  return (
    <Stack align="center" padding="small" margin="xxlarge">
      <Container size="small">{children}</Container>
    </Stack>
  );
};
