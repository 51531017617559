import React, { PropsWithChildren } from 'react';
import { Flex } from '@balance-web/flex';
import { Link } from '@balance-web/link';
import { Text } from '@balance-web/text';

import type { ReckonApp } from '../../../types/ReckonAppDirectory';
import { ProductTypeEnum } from '../../../types/ReckonAppDirectory';

export type AppButtonProps = PropsWithChildren<{
  app: ReckonApp;
}>;

export function AppButton({ app }: AppButtonProps) {
  return (
    <Link href={app.url} target="_new">
      <Flex
        direction="column"
        alignItems="center"
        gap="large"
        cursor="pointer"
        padding="large"
        width={220}
      >
        <img src={app.logoUrl} alt={app.productType} height={40} width={40} />
        <Text size="small" weight="medium">
          {ProductTypeEnum.valueToLabel(app.productType)}
        </Text>
      </Flex>
    </Link>
  );
}
