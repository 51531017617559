import type { PropsWithChildren } from 'react';
import React, { Fragment } from 'react';
import { Flex } from '@balance-web/flex';
import { Inline } from '@balance-web/inline';
import { Heading } from '@balance-web/heading';
import { Text } from '@balance-web/text';
import { TextLink } from '@balance-web/text-link';

import type { ReckonAppDirectory } from '../../../types';
import { Loader } from '../../common/Loader';
import { AppListEmptyState } from '../../app/AppListEmptyState';

import { AppButton } from './AppButton';

export type AppSelectorProps = PropsWithChildren<{
  isLoading?: boolean;
  apps: ReckonAppDirectory;
  missingProductHelpURL: string;

  onAddProductsToAccountClick: () => void;
}>;

export function AppSelector({
  isLoading,
  apps,
  missingProductHelpURL,

  onAddProductsToAccountClick,
}: AppSelectorProps) {
  return (
    <Flex
      direction="column"
      border="standard"
      paddingTop="xxlarge"
      padding="xlarge"
      alignItems="center"
      justifyContent="center"
      gap="xlarge"
      borderRadius="medium"
      background="base"
      boxShadow="large"
      flexGrow={1}
    >
      {isLoading && <Loader label="loading apps..." padding="large" />}

      {!isLoading && !apps.length && (
        <AppListEmptyState
          onAddProductsToAccountClick={onAddProductsToAccountClick}
        />
      )}

      {!isLoading && !!apps.length && (
        <Fragment>
          <Flex paddingTop="small">
            <Heading level="5">Select a product</Heading>
          </Flex>
          <Flex
            wrap={true}
            justifyContent={['center', 'center', 'center', 'none']}
          >
            {apps.map((app) => {
              return <AppButton key={app.productType} app={app} />;
            })}
          </Flex>

          <Inline padding="medium">
            <Text size="small">
              <TextLink href={missingProductHelpURL} target="_blank">
                Don't see your product here?
              </TextLink>
            </Text>
          </Inline>
        </Fragment>
      )}
    </Flex>
  );
}
