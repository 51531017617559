import { createTypedEnum } from '@reckon-web/formschema-enums';

export const ProductTypeEnum = createTypedEnum({
  PAYROLL: 'Payroll',
  RECKON_ONE: 'Reckon One',
  RECKON_GOV_CONNECT: 'Reckon GovConnect',
  INSIGHTS: 'Insights',
  DEADLY_DIGITS: 'Deadly Digits',
});

export type ReckonApp = {
  productType: typeof ProductTypeEnum.values[number];
  logoUrl: string;
  url: string;
};

export type ReckonAppDirectory = ReckonApp[];
