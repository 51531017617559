// ts-gql-integrity:cf99db70624d7e141a428435bbca75e9
/*
ts-gql-meta-begin
{
  "hash": "027409adbc0549361d238b4f11a277b4"
}
ts-gql-meta-end
*/

import * as SchemaTypes from "./@schema";
import { TypedDocumentNode } from "@ts-gql/tag";

type GetUserAccessedAppsQueryQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


type GetUserAccessedAppsQueryQuery = { readonly __typename: 'Query', readonly authenticatedUser: { readonly __typename: 'User', readonly id: string, readonly userProducts: ReadonlyArray<{ readonly __typename: 'ProductTypeDetails', readonly productType: SchemaTypes.ProductTypeEnum | null, readonly url: string | null, readonly logoURL: string | null }> | null } | null };



export type type = TypedDocumentNode<{
  type: "query";
  result: GetUserAccessedAppsQueryQuery;
  variables: {};
  documents: SchemaTypes.TSGQLDocuments;
  fragments: SchemaTypes.TSGQLRequiredFragments<"none">
}>

declare module "./@schema" {
  interface TSGQLDocuments {
    GetUserAccessedAppsQuery: type;
  }
}

export const document = JSON.parse("{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"GetUserAccessedAppsQuery\"},\"variableDefinitions\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"authenticatedUser\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"userProducts\"},\"arguments\":[],\"directives\":[],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"productType\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"url\"},\"arguments\":[],\"directives\":[]},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"logoURL\"},\"arguments\":[],\"directives\":[]}]}}]}}]}}]}")
