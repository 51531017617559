/** @jsx jsx */

import React from 'react';
import { Box, BoxProps } from '@balance-web/box';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';

export const CodeBlock = ({ code, ...props }: BoxProps & { code: string }) => {
  const { palette, radii, typography } = useTheme();

  return (
    <Box
      as="pre"
      css={{
        background: palette.background.muted,
        borderRadius: radii.xsmall,
        boxShadow: `0 0 0 1px ${palette.global.border}`,
        fontFamily: typography.fontFamily.monospace,
        fontSize: typography.fontSize.small,
        color: palette.text.base,
        lineHeight: 1.4,
        overflow: 'auto',
        padding: 10,

        code: {
          fontFamily: 'inherit',
        },
      }}
      {...props}
    >
      <code>{code}</code>
    </Box>
  );
};
